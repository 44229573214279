var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "clustering-analysis" } },
    [
      _c(
        "v-app",
        [
          _c(
            "v-main",
            { staticClass: "bgColorBase wrap-app" },
            [_c("router-view", { on: { setLoader: _vm.setLoader } })],
            1
          ),
          _vm.loading ? _c("Loader") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }