var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center" },
            [
              _c("h1", { staticClass: "font15pt font-weight-bold mr-2" }, [
                _vm._v(" Influencer Identification ")
              ]),
              _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } })
            ],
            1
          )
        ],
        1
      ),
      _c("v-row", [
        _c("div", { staticClass: "graph-secction col" }, [
          _c(
            "div",
            {
              staticClass:
                "graph-secction__base pa-3 bgColorMenu position-relative"
            },
            [
              _vm.modal.id
                ? _c(
                    "div",
                    {
                      staticClass:
                        "zIndex_1 position-absolute white elevation-1",
                      style: {
                        left: _vm.modal.x + "px",
                        top: _vm.modal.y + "px"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "close position-absolute zIndex_1",
                          on: {
                            click: function($event) {
                              return _vm.closeModal()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { size: "20", color: "#2c3852" } },
                            [_vm._v(" fas fa-times ")]
                          )
                        ],
                        1
                      ),
                      _c("v-card", { attrs: { rounded: "0", width: "380" } }, [
                        _vm.modal.type === "mention"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "\n                top_modal\n                d-flex\n                bgGrapg\n                align-center\n                pl-5\n                pt-4\n                pr-5\n                pb-4\n              "
                              },
                              [
                                _c("v-avatar", { attrs: { size: "40" } }, [
                                  _c("img", {
                                    attrs: {
                                      alt: "user",
                                      src: _vm.modal.image
                                    },
                                    on: {
                                      error: function($event) {
                                        $event.target.src =
                                          "https://listen-images.s3.us-east-2.amazonaws.com/user-image.png"
                                      }
                                    }
                                  })
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "white--text font15pt font-weight-bold"
                                  },
                                  [_vm._v(" " + _vm._s(_vm.modal.label) + " ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "body_modal pl-5 pt-4 pr-5 pb-4" },
                          [
                            _vm.modal.type === "influencer"
                              ? _c("div", [
                                  _c("div", { staticClass: "d-flex" }, [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex" },
                                      [
                                        _c(
                                          "v-avatar",
                                          { attrs: { size: "40" } },
                                          [
                                            _c("img", {
                                              attrs: {
                                                alt: "user",
                                                src: _vm.modal.image
                                              },
                                              on: {
                                                error: function($event) {
                                                  $event.target.src =
                                                    "https://listen-images.s3.us-east-2.amazonaws.com/user-image.png"
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-column" },
                                        [
                                          _c("div", { staticClass: "name" }, [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "titleColor font15pt font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.modal.label) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "linkColor font12pt"
                                              },
                                              [
                                                _vm._v(
                                                  " @" +
                                                    _vm._s(_vm.modal.label) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font10pt leyendColor"
                                            },
                                            [_vm._v(_vm._s(_vm.modal.time))]
                                          ),
                                          _vm.modal.is_ret === "true"
                                            ? _c(
                                                "div",
                                                { staticClass: "mt-1 is_rt" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "pl-1 font10pt leyendColor"
                                                    },
                                                    [_vm._v("retweeted of ")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "font10pt ColorMale"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.modal.ret_name
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "font12pt parColor mt-2"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.modal.text) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c("img", {
                                            staticClass: "col pa-0",
                                            attrs: {
                                              src: _vm.modal.post_img,
                                              alt: ""
                                            }
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "d-flex mt-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mr-4 d-none" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        size: "14",
                                                        color: "#a2acc4"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " far fa-comments "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ml-1 font10pt leyendColor"
                                                    },
                                                    [_vm._v("2")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        size: "14",
                                                        color: "#a2acc4"
                                                      }
                                                    },
                                                    [_vm._v(" far fa-heart ")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ml-1 font10pt leyendColor"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.modal.post_like
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "ml-4" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        size: "14",
                                                        color: "#a2acc4"
                                                      }
                                                    },
                                                    [_vm._v(" fas fa-retweet ")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ml-1 font10pt leyendColor"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.modal.post_retweet
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ])
                              : _vm._e(),
                            _vm.modal.type === "mention"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "d-flex flex-column",
                                    class: { ment_: _vm.modal.post.length > 1 }
                                  },
                                  _vm._l(_vm.modal.post, function(item, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "d-flex" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex" },
                                          [
                                            _c(
                                              "v-avatar",
                                              { attrs: { size: "40" } },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    alt: "user",
                                                    src: item.avatar
                                                  },
                                                  on: {
                                                    error: function($event) {
                                                      $event.target.src =
                                                        "https://listen-images.s3.us-east-2.amazonaws.com/user-image.png"
                                                    }
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "col" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex flex-column"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "name" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "titleColor font15pt font-weight-bold"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.label) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "linkColor font12pt"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " @" +
                                                          _vm._s(item.label) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font10pt leyendColor"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.time) +
                                                      " hours "
                                                  )
                                                ]
                                              ),
                                              item.is_ret === "true"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "mt-1 is_rt"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "pl-1 font10pt leyendColor"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "retweeted of "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font10pt ColorMale"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.ret_name
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "font12pt parColor mt-2"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.text) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c("img", {
                                                staticClass: "col pa-0",
                                                attrs: {
                                                  src: item.post_img,
                                                  alt: ""
                                                }
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "d-flex mt-3" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "mr-4 d-none"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            size: "14",
                                                            color: "#a2acc4"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " far fa-comments "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "ml-1 font10pt leyendColor"
                                                        },
                                                        [_vm._v("2")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            size: "14",
                                                            color: "#a2acc4"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " far fa-heart "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "ml-1 font10pt leyendColor"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.post_like
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "ml-4" },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            size: "14",
                                                            color: "#a2acc4"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " fas fa-retweet "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "ml-1 font10pt leyendColor"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.post_retweet
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "footer_modal pl-5 pt-4 pr-5 d-flex pb-4 bgColorBase"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "ico_left" },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { size: "40", color: "#1da1f2" } },
                                  [_vm._v("fab fa-twitter")]
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-8 pt-0" }, [
                              _vm.modal.type === "influencer"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col pa-0 mb-3 d-flex justify-space-between"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "parColor font12pt" },
                                        [_vm._v(" Retweet: ")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "parColor font12pt font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.modal.retweet) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.modal.type === "influencer"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col pa-0 mb-3 d-flex justify-space-between"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "parColor font12pt" },
                                        [_vm._v(" Community: ")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "parColor font12pt font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.modal.community) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.modal.type === "mention" &&
                              _vm.modal.more_than_one_influ
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col pa-0 mb-3 d-flex justify-space-between"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "parColor font12pt" },
                                        [
                                          _vm._v(
                                            " User with more interactions: "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "parColor font12pt font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.modal
                                                  .influ_more_interactions
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col pa-0 mb-3 d-flex justify-space-between"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "parColor font12pt" },
                                    [_vm._v(" Interactions: ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "parColor font12pt font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.modal.interactions) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col pa-0 mb-3 d-flex justify-space-between"
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "parColor font12pt" },
                                    [_vm._v(" Sentiment: ")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "parColor font12pt font-weight-bold"
                                    },
                                    [
                                      _vm.modal.sentiment == "neutral"
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "#828a96"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " far fa-hand-point-right "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.modal.sentiment == "pos"
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "#9fd895"
                                              }
                                            },
                                            [_vm._v(" far fa-thumbs-up ")]
                                          )
                                        : _vm._e(),
                                      _vm.modal.sentiment == "neg"
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "#ff7e7e"
                                              }
                                            },
                                            [_vm._v(" far fa-thumbs-down ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm.modal.type === "influencer"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col pa-0 d-flex justify-space-between"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "parColor font12pt" },
                                        [_vm._v(" Score influencer: ")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "parColor font12pt font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.modal.score) + "% "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c("network", {
                ref: "network",
                attrs: {
                  nodes: _vm.nodes,
                  edges: _vm.edges,
                  options: _vm.options
                },
                on: {
                  "select-node": function($event) {
                    return _vm.networkEvent("selectNode")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "\n          graph-secction__info\n          bgClusteringInfo\n          pa-5\n          d-flex\n          justify-space-between\n        "
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "graph-secction__info__title pl-5 d-flex align-center"
                },
                [
                  _c(
                    "h1",
                    { staticClass: "colorPrincipal font15pt font-weight-bold" },
                    [_vm._v(" Conversion Cluster base on: ")]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "graph-secction__info__icons pr-5 d-flex align-end"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "\n              graph-secction__info__icons__item\n              d-flex\n              flex-column\n              align-center\n              mr-5\n              position-relative\n            "
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "\n                graph-secction__info__icons__item__top\n                mb-1\n                d-flex\n                align-center\n              "
                        },
                        [
                          _c("span", {
                            staticClass:
                              "rounded-circle-complete d-inline-block d-flex justify-center",
                            staticStyle: { width: "20px", height: "20px" }
                          }),
                          _c(
                            "span",
                            {
                              staticClass:
                                "rounded-circle d-inline-block d-flex justify-center ml-1",
                              staticStyle: { width: "20px", height: "20px" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: { "padding-bottom": "2px" },
                                  attrs: { size: "12", color: "#00A5FF" }
                                },
                                [_vm._v("@")]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "graph-secction__info__icons__item__name leyendColor"
                        },
                        [_c("span", [_vm._v("Circles")])]
                      ),
                      _c(
                        "div",
                        { staticClass: "float-content position-relative" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                  white\n                  elevation-1\n                  position-absolute\n                  pa-3\n                  pt-5\n                  pb-5\n                  float-info\n                "
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "arrow-top position-absolute" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "30", color: "white" } },
                                    [_vm._v(" fa fa-caret-up ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-2 font12pt parColor mb-1" },
                                [
                                  _vm._v(
                                    " The width of the circle indicates who is the most influential user. "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font12pt parColor font-weight-bold mb-1"
                                },
                                [_vm._v(" Nodes: ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font11pt colorPrincipal font-weight-bold pl-1"
                                },
                                [_vm._v(" Main Node: ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1" },
                                [
                                  _vm._v(
                                    " Influencer (content generator) with more interactions highlighted with white print. "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font11pt colorPrincipal font-weight-bold pl-1 mt-1"
                                },
                                [_vm._v(" Sub Node: ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1" },
                                [
                                  _vm._v(
                                    " Mentions that generated the most interactions, highlighted in blue. "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "\n              graph-secction__info__icons__item\n              d-flex\n              flex-column\n              align-center\n              position-relative\n            "
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "\n                graph-secction__info__icons__item__top\n                mb-1\n                d-flex\n                align-center\n              "
                        },
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "12", color: "#9fd895" } },
                                [_vm._v("fas fa-long-arrow-alt-up")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "pl-1 pr-1" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "12", color: "#ff7e7e" } },
                                [_vm._v("fas fa-long-arrow-alt-up")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "12", color: "#828a96" } },
                                [_vm._v("fas fa-long-arrow-alt-up")]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "graph-secction__info__icons__item__name leyendColor"
                        },
                        [_c("span", [_vm._v("Color arrows")])]
                      ),
                      _c(
                        "div",
                        { staticClass: "float-content position-relative" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                  white\n                  elevation-1\n                  position-absolute\n                  pa-3\n                  pt-5\n                  pb-5\n                  float-info\n                "
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "arrow-top position-absolute" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "30", color: "white" } },
                                    [_vm._v(" fa fa-caret-up ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-1 font12pt parColor" },
                                [
                                  _vm._v(
                                    " Indicates the sentiment of influencer with the community. "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font12pt parColor font-weight-bold"
                                },
                                [_vm._v("Arrows:")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font11pt colorPrincipal font-weight-bold pl-1 mt-1"
                                },
                                [_vm._v(" Color Arrow: ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor mb-1 pl-1" },
                                [
                                  _vm._v(
                                    " Sentiment generated when relating the Nodes. "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1" },
                                [
                                  _c("span", {
                                    staticClass:
                                      "d-inline-block rounded-circle bgColorpositive mr-1",
                                    staticStyle: {
                                      width: "10px",
                                      height: "10px"
                                    }
                                  }),
                                  _c("span", [_vm._v("Positive sentiment.")])
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1" },
                                [
                                  _c("span", {
                                    staticClass:
                                      "d-inline-block rounded-circle bgColornegative mr-1",
                                    staticStyle: {
                                      width: "10px",
                                      height: "10px"
                                    }
                                  }),
                                  _c("span", [_vm._v("Negative sentiment.")])
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1" },
                                [
                                  _c("span", {
                                    staticClass:
                                      "d-inline-block rounded-circle bgColorneutral mr-1",
                                    staticStyle: {
                                      width: "10px",
                                      height: "10px"
                                    }
                                  }),
                                  _c("span", [_vm._v("Neutral sentiment.")])
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font11pt colorPrincipal font-weight-bold pl-1 mt-1"
                                },
                                [_vm._v(" Thickness arrow: ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor mb-1 pl-1" },
                                [
                                  _vm._v(
                                    " Indicates that there are more interactions between the nodes. "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }