var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center" },
            [
              _c("h1", { staticClass: "font15pt font-weight-bold mr-2" }, [
                _vm._v("Top Influencers")
              ]),
              _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "div",
              { staticClass: "table--general" },
              [
                _c("v-data-table", {
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.itemsTable,
                    "hide-default-header": false,
                    "hide-default-footer": true,
                    light: true
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.user",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "div",
                              [
                                _c("span", { staticClass: "mr-5" }, [
                                  _vm._v(
                                    _vm._s(_vm.itemsTable.indexOf(item) + 1)
                                  )
                                ]),
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "ml-5",
                                    attrs: { size: "25" }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        alt: "user",
                                        src: item.user.avatar
                                      },
                                      on: {
                                        error: function($event) {
                                          $event.target.src =
                                            "https://listen-images.s3.us-east-2.amazonaws.com/user-image.png"
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c("a", { staticClass: "ml-2" }, [
                                  _vm._v(" @" + _vm._s(item.user.name) + " ")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "item.sentiment",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.sentiment == "neutral" ||
                            item.sentiment == "neu"
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "#828a96" } },
                                  [_vm._v(" far fa-hand-point-right ")]
                                )
                              : _vm._e(),
                            item.sentiment == "pos"
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "#9fd895" } },
                                  [_vm._v(" far fa-thumbs-up ")]
                                )
                              : _vm._e(),
                            item.sentiment == "neg"
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "#ff7e7e" } },
                                  [_vm._v(" far fa-thumbs-down ")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }