var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-main",
        {
          staticClass: "center-content clustering_content",
          staticStyle: {
            "margin-top": "150px",
            "padding-left": "5rem !important"
          }
        },
        [
          _vm.clusteringKeywordsSemantic
            ? _c("div", [
                _vm.contextAccountId != "geo-dashboard"
                  ? _c(
                      "div",
                      [
                        _c("Barcontext", {
                          attrs: {
                            contextName: _vm.contextName,
                            "context-keywords": _vm.arrayKeywords
                          },
                          on: {
                            close: function($event) {
                              return _vm.closeSocialFilter(false)
                            },
                            closeMultiSelect: function($event) {
                              return _vm.closeDriverFilter(false)
                            },
                            changeSocialFilter: _vm.changeSocialFilter,
                            dataLoading: _vm.setLoader
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.contextAccountId == "geo-dashboard"
                  ? _c(
                      "div",
                      [
                        _c("BarContextBoards", {
                          attrs: {
                            contextName: _vm.contextName,
                            "context-keywords": _vm.arrayKeywords
                          },
                          on: {
                            updateFbDrivers: _vm.updateFbDrivers,
                            updateTwDrivers: _vm.updateTwDrivers,
                            updateIgDrivers: _vm.updateIgDrivers,
                            updateLkDrivers: _vm.updateLkDrivers,
                            updateNwsDrivers: _vm.updateNwsDrivers,
                            close: function($event) {
                              return _vm.closeSocialFilter(true)
                            },
                            closeMultiSelect: function($event) {
                              return _vm.closeDriverFilter(true)
                            },
                            dataLoading: _vm.setLoader
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "component-content__tabs" },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "component-content__tabs-menu",
                  attrs: {
                    centered: "",
                    "slider-color": "#00A5FF",
                    "background-color": "#f3f4f7",
                    height: "51",
                    grow: ""
                  },
                  model: {
                    value: _vm.model,
                    callback: function($$v) {
                      _vm.model = $$v
                    },
                    expression: "model"
                  }
                },
                [
                  _c("v-tab", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "font15pt titleColor font-weight-bold text-capitalize mr-1"
                      },
                      [_vm._v("Semantic Analysis")]
                    ),
                    _c(
                      "span",
                      [
                        _vm.socialFilter.some(function(d) {
                          return d === "tw"
                        }) || _vm.socialFilter == "all"
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "mr-1",
                                attrs: { size: "16", color: "#1da1f2" }
                              },
                              [_vm._v("fab fa-twitter")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "span",
                      [
                        _vm.socialFilter.some(function(d) {
                          return d === "fb"
                        }) || _vm.socialFilter == "all"
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "mr-1",
                                attrs: { size: "16", color: "#1877f2" }
                              },
                              [_vm._v("fab fa-facebook")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "span",
                      [
                        _vm.socialFilter.some(function(d) {
                          return d === "ig"
                        }) || _vm.socialFilter == "all"
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "mr-1",
                                attrs: { size: "16", color: "#ff69a1" }
                              },
                              [_vm._v("fab fa-instagram")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "span",
                      [
                        _vm.socialFilter.some(function(d) {
                          return d === "lk"
                        }) || _vm.socialFilter == "all"
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "mr-1",
                                attrs: { size: "16", color: "#0a66c2" }
                              },
                              [_vm._v("fab fa-linkedin")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "span",
                      [
                        _vm.socialFilter.some(function(d) {
                          return d === "nws"
                        }) || _vm.socialFilter == "all"
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "mr-1",
                                attrs: { size: "16", color: "#444444" }
                              },
                              [_vm._v("fa-solid fa-newspaper")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("v-tab", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "font15pt titleColor font-weight-bold text-capitalize mr-1"
                      },
                      [_vm._v("influencer")]
                    ),
                    _c(
                      "span",
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "16", color: "#1da1f2" } },
                          [_vm._v("fab fa-twitter")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  staticClass: "component-content__tabs__info pb-0",
                  staticStyle: { padding: "50px" },
                  attrs: { touchless: "" },
                  model: {
                    value: _vm.model,
                    callback: function($$v) {
                      _vm.model = $$v
                    },
                    expression: "model"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    {
                      attrs: {
                        transition: "fade-transition",
                        "reverse-transition": "fade-transition"
                      }
                    },
                    [
                      !_vm.refreshSemantic
                        ? _c(
                            "v-main",
                            { staticClass: "tab-padding" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("GraphSemantic", {
                                        attrs: { words: _vm.words },
                                        on: {
                                          changeSocialFilter:
                                            _vm.changeSocialFilter
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "mt-5 mb-5" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("TextAnalyticsDetail", {
                                        attrs: {
                                          wordsKeySamples: _vm.wordsKeySamples,
                                          hashtagsSamples: _vm.hashtagsSamples,
                                          mentionsSamples: _vm.mentionsSamples
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    {
                      attrs: {
                        transition: "fade-transition",
                        "reverse-transition": "fade-transition"
                      }
                    },
                    [
                      _vm.clusteringKeywordsInfluencer
                        ? _c(
                            "v-main",
                            { staticClass: "tab-padding" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("GraphInfluencer", {
                                        attrs: { influencers: _vm.influencers }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "mt-5 mb-5" },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("TopInfluencer", {
                                        attrs: { influencers: _vm.influencers }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }