var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center mt-5" },
            [
              _c("h1", { staticClass: "font15pt font-weight-bold mr-2" }, [
                _vm._v("Text Analytics Detail")
              ]),
              _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0", attrs: { md: "4" } },
            [
              _c("ClusteringTable", { attrs: { dataTable: _vm.words } }),
              _c("div", { staticClass: "text-right pr-1" }, [
                _c("span", { staticClass: "font10pt leyendColor" }, [
                  _vm._v(_vm._s(_vm.words.itemsTable.length) + " total words")
                ])
              ])
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-0 pr-0", attrs: { md: "4" } },
            [
              _c("ClusteringTable", { attrs: { dataTable: _vm.hashtag } }),
              _c("div", { staticClass: "text-right pr-1" }, [
                _c("span", { staticClass: "font10pt leyendColor" }, [
                  _vm._v(
                    _vm._s(_vm.hashtag.itemsTable.length) + " total hashtags"
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pr-0", attrs: { md: "4" } },
            [
              _c("ClusteringTable", { attrs: { dataTable: _vm.mentions } }),
              _c("div", { staticClass: "text-right pr-1" }, [
                _c("span", { staticClass: "font10pt leyendColor" }, [
                  _vm._v(
                    _vm._s(_vm.mentions.itemsTable.length) + " total mentions"
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }