var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "div",
              { staticClass: "table--general tableClustering" },
              [
                _c("v-data-table", {
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.dataTable.itemsTable,
                    "hide-default-header": false,
                    "hide-default-footer": true,
                    light: true
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.sentiment",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.sentiment == "neutral"
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "#828a96" } },
                                  [_vm._v(" far fa-hand-point-right ")]
                                )
                              : _vm._e(),
                            item.sentiment == "pos"
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "#9fd895" } },
                                  [_vm._v(" far fa-thumbs-up ")]
                                )
                              : _vm._e(),
                            item.sentiment == "neg"
                              ? _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "#ff7e7e" } },
                                  [_vm._v(" far fa-thumbs-down ")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }