var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: {
        bottom: "",
        "max-width": "300",
        "content-class": "font12pt bgColorPrincipal text-center pt-4 pb-4"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-icon",
                _vm._g(
                  _vm._b(
                    { attrs: { size: "13", color: "#a2acc4" } },
                    "v-icon",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" fas fa-info-circle ")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("span", { staticClass: "font-weight-bold" }, [
        _vm._v(_vm._s(_vm.tooltipText))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }