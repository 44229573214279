var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex align-center" },
            [
              _c("h1", { staticClass: "font15pt font-weight-bold mr-2" }, [
                _vm._v("Semantics Of Words")
              ]),
              _c("tooltipComponent", { attrs: { tooltipText: _vm.tooltip } })
            ],
            1
          )
        ],
        1
      ),
      _c("v-row", [
        _c("div", { staticClass: "graph-secction col" }, [
          _c(
            "div",
            { staticClass: "graph-secction__base pa-3 bgColorMenu" },
            [
              _c("network", {
                ref: "network",
                attrs: {
                  nodes: _vm.nodes,
                  edges: _vm.edges,
                  options: _vm.options
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "\n            graph-secction__info\n            bgClusteringInfo\n            pa-5\n            d-flex\n            justify-space-between\n          "
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "graph-secction__info__title pl-5 d-flex align-center"
                },
                [
                  _c(
                    "h1",
                    { staticClass: "colorPrincipal font15pt font-weight-bold" },
                    [_vm._v(" Conversion Cluster base on: ")]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "graph-secction__info__icons pr-5 d-flex align-end"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "\n                graph-secction__info__icons__item\n                d-flex\n                flex-column\n                align-center\n                mr-5\n                position-relative\n              "
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "\n                  graph-secction__info__icons__item__top\n                  mb-1\n                  d-flex\n                  align-center\n                "
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "rounded-circle d-inline-block d-flex justify-center mr-1",
                              staticStyle: { width: "20px", height: "20px" }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "12", color: "#00A5FF" } },
                                [_vm._v("fab fa-facebook")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "rounded-circle d-inline-block d-flex justify-center mr-1",
                              staticStyle: { width: "20px", height: "20px" }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "12", color: "#00A5FF" } },
                                [_vm._v("fab fa-twitter")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "rounded-circle d-inline-block d-flex justify-center mr-1",
                              staticStyle: { width: "20px", height: "20px" }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "12", color: "#00A5FF" } },
                                [_vm._v("fab fa-instagram")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "rounded-circle d-inline-block d-flex justify-center mr-1",
                              staticStyle: { width: "20px", height: "20px" }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "12", color: "#00A5FF" } },
                                [_vm._v("fab fa-linkedin")]
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.networks, function(network) {
                            return [
                              network.type === "newspaper"
                                ? _c(
                                    "span",
                                    {
                                      key: network.name,
                                      staticClass:
                                        "rounded-circle d-inline-block d-flex justify-center mr-1 overflow-hidden",
                                      staticStyle: {
                                        width: "20px",
                                        height: "20px",
                                        padding: "2px"
                                      }
                                    },
                                    [
                                      _c("v-img", {
                                        staticClass: "rounded-circle",
                                        attrs: {
                                          src: network.logo,
                                          width: "12"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "graph-secction__info__icons__item__name leyendColor"
                        },
                        [_c("span", [_vm._v("Nodes")])]
                      ),
                      _c(
                        "div",
                        { staticClass: "float-content position-relative" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                    white\n                    elevation-1\n                    position-absolute\n                    pa-3\n                    pt-5\n                    pb-5\n                    float-info\n                  "
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "arrow-top position-absolute" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "30", color: "white" } },
                                    [_vm._v(" fa fa-caret-up ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-2 font12pt parColor" },
                                [
                                  _vm._v(
                                    " The size of the main node is defined by the number of interactions generated by the driver. "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font12pt parColor font-weight-bold mb-1"
                                },
                                [_vm._v(" Node ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font11pt colorPrincipal font-weight-bold pl-1"
                                },
                                [_vm._v(" Main Node: ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1" },
                                [_vm._v(" Driver (semantic unit). ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font11pt colorPrincipal font-weight-bold pl-1 mt-1"
                                },
                                [_vm._v(" Sub Node: ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1" },
                                [
                                  _vm._v(
                                    " Top 10 words generated by the driver. "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font11pt colorPrincipal font-weight-bold pl-1 mt-1"
                                },
                                [_vm._v(" Social Network Icon: ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1 mb-1" },
                                [
                                  _vm._v(
                                    " indicates the driver's social network. "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1" },
                                [
                                  _c(
                                    "span",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "12",
                                            color: "#1da1f2"
                                          }
                                        },
                                        [_vm._v("fab fa-twitter")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("span", [_vm._v(" Comes from Twitter.")])
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1" },
                                [
                                  _c(
                                    "span",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "12",
                                            color: "#1877f2"
                                          }
                                        },
                                        [_vm._v("fab fa-facebook")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("span", [_vm._v(" Comes from Facebook.")])
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1" },
                                [
                                  _c(
                                    "span",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "12",
                                            color: "#ff69a1"
                                          }
                                        },
                                        [_vm._v("fab fa-instagram")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("span", [_vm._v(" Comes from Instagram.")])
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1" },
                                [
                                  _c(
                                    "span",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "12",
                                            color: "#0a66c2"
                                          }
                                        },
                                        [_vm._v("fab fa-linkedin")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("span", [_vm._v(" Comes from Linkedin.")])
                                ]
                              ),
                              _vm._l(_vm.networks, function(network) {
                                return [
                                  network.type === "newspaper"
                                    ? _c(
                                        "div",
                                        {
                                          key: network.name,
                                          staticClass:
                                            "font11pt parColor pl-1 d-flex"
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "pr-1" },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  src: network.logo,
                                                  width: "12"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              " Comes from " +
                                                _vm._s(network.name) +
                                                "."
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "\n                graph-secction__info__icons__item\n                d-flex\n                flex-column\n                align-center\n                position-relative\n              "
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "\n                  graph-secction__info__icons__item__top\n                  mb-3\n                  d-flex\n                  align-center\n                "
                        },
                        [
                          _c("span", {
                            staticClass: "bgColorpositive d-inline-block",
                            staticStyle: { width: "10px", height: "3px" }
                          }),
                          _c("span", {
                            staticClass: "ml-1 bgColornegative d-inline-block",
                            staticStyle: { width: "10px", height: "3px" }
                          }),
                          _c("span", {
                            staticClass: "ml-1 bgColorneutral d-inline-block",
                            staticStyle: { width: "10px", height: "3px" }
                          })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "graph-secction__info__icons__item__name leyendColor"
                        },
                        [_c("span", [_vm._v("Lines")])]
                      ),
                      _c(
                        "div",
                        { staticClass: "float-content position-relative" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                    white\n                    elevation-1\n                    position-absolute\n                    pa-3\n                    pt-5\n                    pb-5\n                    float-info\n                  "
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "arrow-top position-absolute" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "30", color: "white" } },
                                    [_vm._v(" fa fa-caret-up ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-2 font12pt parColor" },
                                [
                                  _vm._v(
                                    " Indicates the sentiment generated by the driver in relation to the associated words. "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font11pt colorPrincipal font-weight-bold"
                                },
                                [_vm._v(" Line ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "font12pt parColor mb-1" },
                                [
                                  _vm._v(
                                    " Each color indicates the type of sentiment of the driver, the relationship and the word. "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1" },
                                [
                                  _c("span", {
                                    staticClass:
                                      "d-inline-block rounded-circle bgColorpositive mr-1",
                                    staticStyle: {
                                      width: "10px",
                                      height: "10px"
                                    }
                                  }),
                                  _c("span", [_vm._v("Positive sentiment.")])
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1" },
                                [
                                  _c("span", {
                                    staticClass:
                                      "d-inline-block rounded-circle bgColornegative mr-1",
                                    staticStyle: {
                                      width: "10px",
                                      height: "10px"
                                    }
                                  }),
                                  _c("span", [_vm._v("Negative sentiment.")])
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font11pt parColor pl-1" },
                                [
                                  _c("span", {
                                    staticClass:
                                      "d-inline-block rounded-circle bgColorneutral mr-1",
                                    staticStyle: {
                                      width: "10px",
                                      height: "10px"
                                    }
                                  }),
                                  _c("span", [_vm._v("Neutral sentiment.")])
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }